<template>
<svg :width="width" :height="height" :fill="color" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<title>icons/sort/a-z</title>
<g id="icons/sort/a-z" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
<rect id="Rectangle" stroke-opacity="0.458615603" stroke="#F4F4F4" fill="#FFFFFF" x="0.5" y="0.5" width="19" height="19"></rect>
<path d="M2,5 C2,4.44772 2.44772,4 3,4 L17,4 C17.5523,4 18,4.44772 18,5 C18,5.55228 17.5523,6 17,6 L3,6 C2.44772,6 2,5.55228 2,5 Z M2,10 C2,9.4477 2.44772,9 3,9 L13,9 C13.5523,9 14,9.4477 14,10 C14,10.5523 13.5523,11 13,11 L3,11 C2.44772,11 2,10.5523 2,10 Z M3,14 C2.44772,14 2,14.4477 2,15 C2,15.5523 2.44772,16 3,16 L9,16 C9.5523,16 10,15.5523 10,15 C10,14.4477 9.5523,14 9,14 L3,14 Z" id="Combined-Shape" fill="#727272" fill-rule="nonzero" transform="translate(10.000000, 10.000000) scale(1, -1) translate(-10.000000, -10.000000) "></path>
</g>
</svg>
</template>
<script>
export default {
  name: 'ascIconSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'gray'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
