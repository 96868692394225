<template>
  <div class="">
      <div class="mt-8">
        <div class="flex flex-wrap mb-4">
          <div class="" v-for="(status, index) in statusList" :key="index" >
              <StatusBtn class=" whitespace-nowrap" @buttonAction="toggleActiveAndInactive(index)" :selected="status.selected" :btnText="status.statusName" :lastInlist="statusList.length === index + 1"/>     
          </div>
        </div>
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Job...`"/>
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewjob(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Job'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="lead_name_col uppercase w-14  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(0)">{{titleObj[0].title}}</span>
               <div v-if="titleObj[0].icon !== ''">
                  <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="lead_name_col uppercase w-72 flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
               <div v-if="titleObj[1].icon !== ''">
                  <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="lead_name_col uppercase w-56 flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
               <div v-if="titleObj[2].icon !== ''">
                  <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="lead_name_col uppercase w-56 flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''">
              <span class="cursor-pointer" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
               <div v-if="titleObj[3].icon !== ''">
                  <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
          </div>
          <div v-if="jobList.length > 0">
            <div v-for="(data, index) in jobList" :key="index" @click="showJobDetail(data.jobId)" class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer">
              <div class="lead_name_col w-14">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6"># {{data.jobNumber}}</span>
                </div>
              </div>
              <div class="lead_name_col w-72">
                <div class="flex items-center">
                <span class="  text-text2 font-bold heading-6">{{data.jobTitle === '' ? '-' : data.jobTitle}}</span>
                </div>
              </div>
              <div class="lead_name_col w-56">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.companyName === '' ? '-' : data.companyName}}</span>
                </div>
              </div>
              <div class="lead_name_col w-56">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6" v-if="data.nextVisitDateTime !== ''">{{data.nextVisitDateTime | formatDataeTime}}</span>
                <span class="  text-gray4 heading-6" v-else>-</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div>
          <Pagination
            :totalDataCount="totalJobCount"
            :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getLeadDataFromPagination"/>
        </div>
        </div>
      </div>
     </div>
</template>
<script>
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import deboucneMixin from '@/mixins/debounce.js'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import StatusBtn from "@/View/components/statu-filter-btn.vue";
export default {
  name: "lead",
  components: {
    StatusBtn,
    Pagination,
    NoRecords,
    descIconSvg,
    ascIconSvg,
    SearchTextInput,
    Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        {title: '#', icon: '', sortName: 'jobNumber'},
        {title: 'Job Title', icon: '', sortName: 'jobTitle'},
        {title: 'Company Name', icon: '', sortName: 'companyName'},
        {title: 'Next Visit Date', icon: 'DESC', sortName: 'nextVisitDateTime'}
      ],
      statusList: [{
        jobStatusId: 1,
        statusName: 'Open',
        selected: true,
      },
      {
        jobStatusId: 2,
        statusName: 'Working',
        selected: true,
      },
      {
        jobStatusId: 3,
        statusName: 'Closed',
        selected: true,
      }],
      jobList: [],
      searchForJob: '',
      getJobDebounce: null,
      resetPaginationValues: false,
      totalJobCount: 0,
      customerDetailId: 0,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        sortBy: 'nextVisitDateTime',
        statusList: []
      },
    };
  },
  props: ["customerId"],
  created() {},
  mounted() {
    if (this.customerId > 0) {
        this.customerDetailId = this.customerId
    }
    this.getJobsList()
     this.getJobDebounce = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
  },
  methods: {
    addNewjob () {
      this.$router.push({name: 'addNewJob', query: {customerId: this.customerDetailId}})
    },
    showJobDetail (id) {
      this.$router.push({name: 'jobDetail', params: {jobId: id}})
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getJobsList(true)
    },
    sortByColumn (index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getJobsList(true)
    },
    searchCall (data) {
      this.searchForJob = data
      this.getJobDebounce()
    },
    toggleActiveAndInactive (index) {
      this.statusList[index].selected = !this.statusList[index].selected
      this.resetPaginationValues = true
      this.getJobsList()
    },
    getJobsList (showlistLoader) {
      if (showlistLoader && this.searchForJob !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchForJob === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      this.filterObject.statusList = []
      this.statusList.map((data) => {
      if (data.selected) {
        this.filterObject.statusList.push(data.jobStatusId)
      }
    })
      MyJobApp.getJobList(
         this.filterObject.start,
          this.filterObject.length,
          this.searchForJob,
          this.filterObject.sortBy,
          this.filterObject.sortOrder,
          '',
          this.customerDetailId,
          false,
          this.filterObject.statusList,
          '',
          response => {
            this.totalJobCount = response.Data.count
            this.jobList = response.Data.tableRow !== null ? response.Data.tableRow : []
            this.resetPaginationValues = false
            this.$emit('jobcount', this.totalJobCount)
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  }
};
</script>
<style scoped>
.lead_name_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>